import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary">
        {'Copyright © '}
        <Link color="inherit" href="https://sprinterbox.fr/">
          Sprinterbox.fr
        </Link>{' 2021'}
        {new Date().getFullYear() > 2021 ? ' - ' + new Date().getFullYear() : '' }
        {'.'}
      </Typography>
    );
};

export default Copyright;