
import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useHistory } from "react-router-dom";

import logo from "./../assets/logo.png";

const NavBar = () => {

  // navigation history
  const history = useHistory();

  // overall styles
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
      cursor: "pointer"
    },
    titleImg: {
      width: "100px",
      height: "auto",
      cursor: "pointer"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  // menu-related code
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuId = 'user-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Se connecter</MenuItem>
      <MenuItem onClick={handleMenuClose}>S'inscrire</MenuItem>
    </Menu>
  );


  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar>
          <img src={logo} alt="app' logo" className={classes.titleImg} onClick={() => {history.push("/")}}/>
          {/* <Typography variant="h6" className={classes.title} onClick={() => {history.push("/")}}>
            Sprinterbox.fr
          </Typography> */}
          {/* TODO activate later */}
          {/* <IconButton 
            edge="end" 
            className={classes.menuButton} 
            color="inherit" 
            aria-label="user account menu"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <AccountCircle fontSize="large" />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );

};

export default NavBar;