import React, { Fragment, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import EuroIcon from '@material-ui/icons/Euro';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';

import NavBar from "./../components/NavBar";

// TODO find alternative to recaptcha
// import { gRecaptchaSiteKey } from '../conf';
// import { sendQuoteRequest, verifyGRecaptchaToken } from '../helpers/apis-helpers';
import { sendQuoteRequest } from '../helpers/apis-helpers';

import Alert from '../components/Alert';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Quote = () => {

  const classes = useStyles();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState( "info" );
    const [snackbarMessage, setSnackbarMessage] = useState( "Merci ! L'équipe SprinterBox vous contactera très vite !" );
    const [quoteRequest, setQuoteRequest] = useState( {
        firstName: '',
        lastName: '',
        cie: '',
        pickupAddr: '',
        deliveryAddr: '',
        email: '',
        tel: '',
        customerRequest: '',
        consent: "false"
    });

    // SO handlers
    const handleConsentChange = e => {
        setQuoteRequest( {...quoteRequest, consent: quoteRequest.consent === "true" ? "false": "true"} );
    };

    const handleInputChange = e => {
        setQuoteRequest( {...quoteRequest, [e.target.name]: e.target.value} );
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSnackbarOpen(false);
        const setErrFeedback = (mess="Désolé, votre demande de devis ne nous est pas parvenue, veuillez réessayer plus tard") => {
            setSnackbarSeverity( "error" );
            setSnackbarMessage(mess);
        }
        const setSuccFeedback = (mess="Merci ! L'équipe SprinterBox vous contactera très vite !") => {
            setSnackbarSeverity( "info" );
            setSnackbarMessage(mess);
        }

        const processQuoteRequest = async (qreq) => {
            let quoteRequestOutcome = false;
            quoteRequestOutcome = await sendQuoteRequest(qreq);
            if(!quoteRequestOutcome)
                setErrFeedback();
            else
                setSuccFeedback();
            setSnackbarOpen(true);
        }

        processQuoteRequest(quoteRequest);

        // TODO find alternative to recaptcha
        // window.grecaptcha.ready( async () => {
        //     let quoteRequestOutcome = false;
        //     const token = await window.grecaptcha.execute( gRecaptchaSiteKey, {action: "get_a_quote"} );
        //     const tokenVerifOutcome = await verifyGRecaptchaToken( token );
        //     if ( tokenVerifOutcome ) {
        //         quoteRequestOutcome = await sendQuoteRequest( quoteRequest );
        //     }
        //     if (!tokenVerifOutcome) 
        //         setErrFeedback("erreur reCaptcha, veuillez réessayer plus tard");
        //     else if(!quoteRequestOutcome)
        //         setErrFeedback();
        //     else
        //         setSuccFeedback();
        //     setSnackbarOpen( true );
        // });
    };
    // EO handlers

  return (
    <Fragment>
        <NavBar />
        <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
            <EuroIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            Estimer une course
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit} >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                <TextField
                    onBlur={handleInputChange}
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="Prénom"
                    autoFocus
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    onBlur={handleInputChange}
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Nom"
                    name="lastName"
                    autoComplete="lname"
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    onBlur={handleInputChange}
                    variant="outlined"
                    fullWidth
                    id="cie"
                    label="Société"
                    name="cie"
                    autoComplete="cie"
                />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        onBlur={handleInputChange}
                        variant="outlined"
                        required
                        fullWidth
                        id="pickupAddr"
                        label="Adresse d'enlèvement"
                        name="pickupAddr"
                        autoComplete="pickupAddr"
                    />
                </Grid>                
                <Grid item xs={12}>
                    <TextField
                        onBlur={handleInputChange}
                        variant="outlined"
                        required
                        fullWidth
                        id="deliveryAddr"
                        label="Adresse de livraison"
                        name="deliveryAddr"
                        autoComplete="deliveryAddr"
                    />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    onBlur={handleInputChange}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Adresse email"
                    name="email"
                    autoComplete="email"
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    onBlur={handleInputChange}
                    variant="outlined"
                    required
                    fullWidth
                    id="tel"
                    label="N° de téléphone"
                    name="tel"
                    autoComplete="tel"
                />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        onBlur={handleInputChange}
                        id="customerRequest"
                        name="customerRequest"
                        required
                        fullWidth
                        label="Votre besoin"
                        multiline
                        rows={5}
                        variant="outlined"
                        placeholder="Détaillez ici l'objet à transporter (taille, poids, quantité, etc.)"
                    />
                </Grid>
                <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox value="consent" color="primary" name="consent" onChange={handleConsentChange}/>}
                    label=" * J'accepte que les informations recueillies fassent l’objet d’un traitement informatique par Sprinterbox.fr pour envoyer et livrer le colis commandé au destinataire. Le destinataire dispose d'un droit d'accès, d'interrogation, de rectification et d'effacement des informations le concernant, de limitation du traitement et d'un droit à la portabilité de ses données. Il dispose également du droit de définir des directives relatives à la conservation, à l’effacement et à la communication de ses données à caractère personnel après son décès. Ces droits peuvent être exercés par courrier postal à l'adresse suivante : Sprinterbox.fr : 8 bis Rue Abel75012 Paris. Le destinataire dispose en outre d'un droit de réclamation auprès de la Commission Nationale de l'Informatique et des Libertés, située 3 Place de Fontenoy, TSA 80715, 75334 PARIS CEDEX"
                />
                </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Estimer une course
            </Button>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Link to="/" variant="body2">Retour à l'accueil</Link>
                </Grid>
            </Grid>
            </form>
        </div>
        <Snackbar
            open={snackbarOpen}
            closeFunc={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
            </Alert>
        </Snackbar>
        </Container>
    </Fragment>
  );
}

export default Quote;
