import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

// TODO find alternative to recaptcha
// import { sendProductCTAContactRequest, verifyGRecaptchaToken } from '../../helpers/apis-helpers';
import { sendProductCTAContactRequest } from '../../helpers/apis-helpers';
// import { gRecaptchaSiteKey } from '../../conf';

import logoFilled from "./../../assets/logo-filled.jpg";
import Alert from "./../Alert";

const ProductCTA = () => {

  // SO state and effects
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState( "info" );
  const [snackbarMessage, setSnackbarMessage] = useState( "Merci ! L'équipe SprinterBox vous contactera très vite !" );
  const [emailOrTel, setEmailOrTel] = useState( "" );
  // EO state and effects

  // SO handlers
  const handleEmailOrTelChange = ( event ) => {
    setEmailOrTel( event.target.value );
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = (event) => {

    event.preventDefault();

    setSnackbarOpen(false);

    const setErrFeedback = (mess = "Désolé, votre demande de contact ne nous est pas parvenue, veuillez réessayer plus tard") => {
      setSnackbarSeverity( "error" );
      setSnackbarMessage(mess);
    }

    const setSuccFeedback = (mess = "Merci ! L'équipe SprinterBox vous contactera très vite !") => {
      setSnackbarSeverity("info");
      setSnackbarMessage(mess);
    }

    sendProductCTAContactRequest( emailOrTel )
      .then( outcome => {
        if ( !outcome )
          setErrFeedback("votre formulaire n'est pas valide, veuillez réessayer");
        else
          setSuccFeedback();
      })
      .catch( error => {
        setErrFeedback("une erreur est survenue sur nos serveurs, veuillez réessayer plus tard");
      })
      .finally( () => setSnackbarOpen(true));

    // TODO find alternative to recaptcha
    // window.grecaptcha.ready(() => {
    //   window.grecaptcha.execute( gRecaptchaSiteKey, { action: 'be_contacted' }).then( token => {
    //     verifyGRecaptchaToken( token )
    //       .then( outcome => {
    //         if ( !outcome )
    //           setErrFeedback("erreur reCaptcha, veuillez réessayer plus tard");
    //         else {
    //           setSuccFeedback();
    //           sendProductCTAContactRequest( emailOrTel )
    //             .then( outcome => {
    //               if ( !outcome )
    //                 setErrFeedback("votre formulaire n'est pas valide, veuillez réessayer");
    //             })
    //             .catch( error => {
    //               setErrFeedback("une erreur est survenue sur nos serveurs, veuillez réessayer plus tard");
    //             });
    //         }
    //       })
    //       .catch( error => {
    //         setErrFeedback();
    //       })
    //       .finally( () => setSnackbarOpen( true ) );
    //   });
    // });

  };
  // EO handlers

  return (
    <Container 
      component="section" 
      sx={{ mt: 10, display: 'flex' }}
      style={{
        padding: '1rem 0',
      }}   
    >
      <Grid container>
        <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              bgcolor: 'warning.main',
              py: 8,
              px: 3,
            }}
          >
            <Box 
              component="form" 
              onSubmit={handleSubmit} 
              sx={{ maxWidth: 400 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h2" component="h2" gutterBottom>
                N’hésitez pas à nous contacter pour des tarifs sur mesure.
              </Typography>
              <Typography variant="h5">
                Collectes et livraisons pour les professionnels et les particuliers en 24h ou 48h.
              </Typography>
              <TextField
                noBorder
                placeholder="Votre email ou téléphone"
                variant="standard"
                sx={{ width: '100%', mt: 3, mb: 2 }}
                style={{
                  margin: '1rem',
                }}
                value={emailOrTel}
                onChange={handleEmailOrTelChange}
                required
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{ width: '100%' }}
              >
                Contactez-moi
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
        >
          <Box
            component="img"
            src={logoFilled}
            alt="contactez-moi"
            sx={{
              width: '100%',
              maxWidth: 600,
            }}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        closeFunc={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ProductCTA;
