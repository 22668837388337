
export const loadScriptByURL = ( id, url, callback ) => {
    const scriptExists = document.getElementById( id );
    if ( !scriptExists ) {
      let script = document.createElement( "script" );
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if ( callback ) callback();
      };
      document.body.appendChild( script );
    }
    if ( scriptExists && callback ) callback();
};

export const sendProductCTAContactRequest = emailOrTel => {
  let outcome = false;
  // call a backend API to verify reCAPTCHA response
  return fetch( 'https://sprinterboxapi.fr/api/contact-requests', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      "emailOrTel": emailOrTel
    })
  })
  .then( res => res.json() )
  .then( res => res.outcome && res.outcome === true)
  .catch( err => {console.error( err );} )
  .finally( () => outcome );
};

export const sendQuoteRequest = quotePayload => {
  let outcome = false;
  // call a backend API to verify reCAPTCHA response
  return fetch( 'https://sprinterboxapi.fr/api/quotes', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(quotePayload)
  })
  .then( res => {
    // console.log( res.json() ); debugger;
    return res.json()
  })
  .then( res => {
    return res.outcome && res.outcome === true
  })
  .catch( err => {
    console.log( err );
  })
  .finally( () => outcome );
};

export const verifyGRecaptchaToken = async ( token ) => {
  let outcome = false;
  // call a backend API to verify reCAPTCHA response
  return fetch( 'https://sprinterboxapi.fr/api/grecaptcha-verify', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      "gRecaptchaToken": token
    })
  })
  .then( res => res.json() )
  .then( res => res.outcome && res.outcome === true)
  .catch( err => {console.error( err );} )
  .finally( () => outcome );
};