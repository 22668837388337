import React, {Fragment} from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

import Markdown from '../modules/Markdown';
import NavBar from '../components/NavBar';

export const PrivacyNotice = () => {
  return (
    <Fragment>
      <NavBar />
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Politique de confidentialité
          </Typography>
          <Markdown>{`
Dernière mise à jour: Le 29 août 2021.

## Préambule

1. Sprinterbox.fr 8bis, Rue Abel 75012 Paris, en sa qualité de responsable de traitement, s’est. engagée dans une politique de respect de la réglementation relative à la protection des données à caractère personnel.
2. Dans le cadre de son activité économique, Sprinterbox.fr est, en effet, amenée à collecter et à traiter des données à caractère personnel relatives à ses clients, ses candidats, les visiteurs de son site et de ses réseaux sociaux, les destinataires des plis livrés par Sprinterbox.fr et de ses e-mails ainsi que ses partenaires économiques.
3. Soucieuse de développer son activité dans le respect des droits des personnes, Sprinterbox.fr a mis en place les moyens techniques et organisationnels nécessaires pour protéger les données à caractère personnel qu’elle traite.
4. La présente politique a pour objet de présenter les engagements pris par Sprinterbox.fr en matière de protection des données à caractère personnel.

## Les bases juridiques et les finalités de nos traitements de données

5. Sprinterbox.fr collecte vos données personnelles sur les bases juridiques suivantes :

	- Sur la base de votre consentement
		- Afin de déposer des cookies sur votre terminal ;
		- Aux fins de réalisation d’activités marketing ciblées par Sprinterbox.fr ou auprès de tiers partenaires.
	- Sur la base d’obligations précontractuelles et/ou contractuelles à votre égard ou à l’égard d’un client de Sprinterbox.fr (Article 6 b. du RGPD) et notamment afin d’assurer :
		- Les services d’expédition de colis et la prise en compte des modifications de Données de livraison ;
		- Le suivi des services Sprinterbox.fr;
		- La communication avec les destinataires et d’autres parties concernant les services ;
		- La réponse aux demandes d’informations (supplémentaires) ;
		- Le traitement des réclamations ;
		- La résolution des litiges ;
		- La préparation d’accords (par exemple, associer le numéro de suivi de l’expédition à votre compte pour vous permettre de suivre votre envoi) ;
		- Le traitement de votre demande de partenariat distributeur ou entreprise ;
		– Le traitement des candidatures reçues.
	- Sur la base du respect de nos obligations légales. Dans certains cas, nous sommes soumis à certaines obligations qui nous imposent de traiter et/ou transmettre certaines données aux administrations ou institutions déterminées par la loi dans notre intérêt légitime de traiter vos données en vue des finalités suivantes: 
		- – réaliser des activités marketing ciblées par Sprinterbox.fr ou auprès de tiers partenaires ;
		- faire des analyses statistiques ou de tendance pour nous permettre d’améliorer nos services ;
		- gérer administrativement et techniquement le Site et l’exploiter ;
		- prévenir, détecter et combattre les éventuelles fraudes et abus ;
		- traiter vos demandes de contact.
		
## Catégorie de données traitées

6. Les données personnelles vous concernant qui sont traitées par Sprinterbox.fr sont les suivantes:

### Navigation sur le Site, gestion administrative et technique du Site, prévention/détection/combat de la fraude :
Données obligatoires: Adresse IP, Métadonnées (heure, date de connexion), Cookies

### Formulaires de contact:
Données obligatoires: Adresse mel, numéro de téléphone

### Services d’expédition de colis et suivi de ces services :
Données obligatoires: Civilité, Nom / Prénom, Adresse e-mail, Numéro(s) de téléphone, Adresses postales d'enlèvement et de livraison des colis, Numéro de colis.
Données facultatives: Complément(s) d’adresse et Lieu-dit, Informations/instructions de livraison supplémentaires, nom de la société?

### Activités marketing (ciblées) :
Données obligatoires: Prospection commerciale : nom, prénom, adresse postale, client chargeur de référence

7. Notre site web , application mobile, et réseaux sociaux ne sont pas déstinés à un public mineur, raison pour laquelle nous ne collectons pas sciemment des données relatives au enfants.
	

## Les destinataires de vos données

8. Les Données collectées font l’objet d’un traitement informatique et sont destinées à l’usage propre de Sprinterbox.fr, d’un autre responsable de traitement en qualité de destinataire, d’un sous-traitant dans le cadre de prestations exécutées pour son compte et, si requis, aux organismes publics, auxiliaires de justice, officiers ministériels (respect de nos obligations légales), et pour les seules finalités définies au « 2) Bases juridiques et finalités du traitement ».

## Destinataires et responsables de traitement :

- Partenaires commerciaux
- Google
- Réseaux sociaux

9. Sprinterbox.fr veille à ce que seules les personnes habilitées puissent avoir accès à ces données.

## Les transferts de vos données

10. Sprinterbox.fr transfère vos données en dehors de l’Union européenne seulement si ce transfert est fondé sur des dérogations pour des situations particulières (votre consentement).

11. Si Sprinterbox.fr devait avoir besoin de transférer des données en dehors de l’Union européenne en dehors de ces cas dérogatoires ou à d’autres sous-traitants, elle ne le ferait qu’après avoir pris les mesures nécessaires et adéquates pour assurer un niveau de protection et de sécurité des données personnelles équivalent à celui 
proposé au sein de l’Union européenne, ce dont elle vous informerait préalablement.

## Les durées pour lesquelles nous conservons vos données

12. Sprinterbox.fr conserve vos Données pour une durée strictement nécessaire aux finalités poursuivies et conforme à la Règlementation. La période de conservation peut varier en fonction de la finalité des traitements.

	- Données liées aux demandes de contact, partenariat distributeurs et entreprises : 3 ans à compter du dernier contact
	- Données liées aux services d’expédition de colis, au suivi de ces services, au traitement des réclamations et résolution des litiges : 9 mois à compter de la dernière action sur le colis, soit à compter de la fin du traitement d’une réclamation s’il y a eu réclamation.
	- Données susceptibles de faire l’objet d’une gestion technique du Site (Données de connexion, identité) : 6 mois pour les logs utilisateurs
	
Constitue un « dernier contact » l’action suivante : 
- La dernière communication en provenance de la personne à l’origine de la demande de contact

13.  Il est précisé que ces données peuvent être conservées suivant les règles de prescription applicable en base archive intermédiaire et accès restreint afin notamment de se conformer aux obligations légales applicables.

## La sécurité de vos données

14. Sprinterbox.fr accorde une importance particulière à la sécurité des données personnelles.
15. Des mesures techniques et organisationnelles appropriées sont mises en œuvre pour que les données soient traitées de façon à garantir leur protection contre la perte, la destruction ou les dégâts d’origine accidentelle qui pourraient porter atteinte à leur confidentialité ou à leur intégrité.
16. Lors de l’élaboration, de la conception, ou lors de la sélection et de l’utilisation des différents outils qui permettent le traitement de vos données personnelles, Sprinterbox.fr s’assure qu’ils permettent d’assurer un niveau de protection optimal des données traitées.
17. Sprinterbox.fr met ainsi en œuvre des mesures qui respectent les principes de protection dès la conception et de protection par défaut des données traitées.

## La sous-traitance

18. Lorsqu’elle a recours à un prestataire, Sprinterbox.fr ne lui communique des données personnelles qu’après avoir obtenu de ce dernier un engagement et des garanties sur sa capacité à répondre à ces exigences de sécurité et de confidentialité. Sprinterbox.fr contracte avec ses sous-traitants dans le respect de ses obligations légales et réglementaires en définissant contractuellement les conditions et modalités de traitement des données personnelles par ces derniers.

## Les droits qui vous sont reconnus

19. Sprinterbox.fr est particulièrement soucieuse du respect des droits qui vous sont accordés dans le cadre des traitements de données qu’elle met en œuvre, pour vous garantir des traitements équitables et transparents compte tenu des circonstances particulières et du contexte dans lesquels vos données personnelles sont traitées.

### Votre droit d’accès

20. Par ce droit, vous avez la confirmation que vos données personnelles sont ou ne sont pas traitées et lorsqu’elles le sont, vous disposez du droit de demander une copie de vos données et des informations vous concernant.

### Votre droit à la rectification de vos données
21. Vous pouvez nous demander que vos données personnelles soient, selon les cas, rectifiées, complétées si elles sont inexactes, incomplètes, équivoques, périmées.

### Votre droit à l’effacement de vos données
22. Vous pouvez demander l’effacement de vos données personnelles lorsque l’un des motifs suivants s’applique 
– les données personnelles ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou traitées d’une autre manière ;
– vous retirez le consentement préalablement donné ;
– vous vous opposez au traitement de vos données personnelles lorsqu’il n’existe pas de motif légitime impérieux pour le traitement ;
– le traitement de données personnelles n’est pas conforme aux dispositions de la législation et de la réglementation applicable.
23. Votre attention est attirée sur le fait que le droit à l’effacement des données n’est pas un droit général et qu’il ne pourra y être fait droit que si un des motifs prévus dans la réglementation applicable est présent.
24. Ainsi, si aucun de ces motifs n’est présent, Sprinterbox.fr ne pourra répondre favorablement à votre demande ; tel sera le cas si elle est tenue de conserver les données en raison d’une obligation légale ou réglementaire ou pour la constatation, l’exercice ou la défense de droits en justice.

### Votre droit à la limitation des traitements de données
25. Vous pouvez demander la limitation du traitement de vos données personnelles dans les cas prévus par la législation et la réglementation.

### Votre droit de vous opposer aux traitements de données
26. Vous disposez du droit de vous opposer à tout moment, pour des raisons tenant à votre situation particulière, à un traitement de vos données personnelles dont la base juridique est l’intérêt légitime.
27. En cas d’exercice d’un tel droit d’opposition, Sprinterbox.fr veillera à ne plus traiter vos données personnelles dans le cadre du traitement concerné sauf si nous pouvons démontrer que nous pouvons avoir des motifs légitimes et impérieux pour maintenir ce traitement. Ces motifs devront être supérieurs à vos intérêts et à vos droits et libertés, ou le traitement se justifier pour la constatation, l’exercice ou la défense de droits en justice.

### Votre droit à la portabilité de vos données
28. Vous disposez du droit à la portabilité de vos données personnelles. Nous attirons votre attention sur le fait qu’il ne s’agit pas d’un droit général. En effet, toutes les données de tous les traitements ne sont pas portables et ce droit ne concerne que les traitements automatisés à l’exclusion des traitements manuels ou papiers.
29. Ce droit est limité aux traitements dont la base juridique est votre consentement ou l’exécution des mesures précontractuelles ou d’un contrat.
30. Le droit à la portabilité ne peut pas porter atteinte aux droits et libertés de tiers telles que celles protégées par le secret des affaires.

### Votre droit de retirer votre consentement
31. Lorsque les traitements de données que nous mettons en œuvre sont fondés sur votre consentement, vous pouvez le retirer à n’importe quel moment. Sprinterbox.fr cessera alors de traiter vos données personnelles sans que les opérations antérieures pour lesquelles vous aviez consenti ne soient remises en cause.

### Le droit de ne pas faire l’objet d’une décision fondée sur un traitement automatisé
32. Dans le cadre de ses activités, Sprinterbox.fr est susceptible de mettre en place des traitements automatisés des données. Vous avez le droit de ne pas faire l’objet d’une décision fondée exclusivement sur un traitement automatisé produisant des effets juridiques vous concernant ou vous affectant, sauf lorsque cette décision est indispensable à la conclusion ou à l’exécution d’un contrat, ou est autorisée légalement.

### Votre droit d’introduire une réclamation
33. Vous avez le droit d’introduire une réclamation auprès de la Cnil (3 place de Fontenoy 75007 Paris) sur le territoire français.

### Votre droit de définir des directives post-mortem
34. Vous avez la possibilité de définir des directives particulières relatives à la conservation, à l’effacement et à la communication de vos données personnelles après votre décès auprès de nos services selon les modalités ci-après définies. Ces directives particulières ne concerneront que les traitements mis en œuvre par nos soins et seront limitées à ce seul périmètre.

### Les modalités d’exercice de vos droits
35. Les demandes relatives à l’exercice de vos droits s’effectuent par email à l’adresse contact@sprinterbox.fr ou par courrier à l’adresse postale suivante :
	SPRINTERBOX.FR
	8 Bis, Rue Abel 75012 Paris
en justifiant de votre identité par tout moyen, tel que par la communication de la copie de votre carte d’identité.

### Modification du présent document
36. Nous vous invitons à consulter régulièrement cette politique sur notre site internet. Elle pourra faire l’objet de mises à jour

Août 2021

          `}</Markdown>
        </Box>
      </Container>
    </Fragment>
  );
}

export default PrivacyNotice;
