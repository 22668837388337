import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";

import Copyright from './Copyright';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

export default function Footer() {

  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Typography variant="body1">
            {/* <p>Twiter-linked-facebook-instagram</p> */}
            <p><Link to="/privacy-notice">Politique de confidentialité</Link> – <Link to="/legal-notice">Mentions légales</Link> – <Link to="/cookies-policy">Politique de cookies</Link> – <Link to="/terms-of-sale">CGV</Link> – <a href="https://www.cnil.fr/fr/donnees-personnelles" target="_blank" rel="noreferrer">Protection des données</a></p>
        </Typography>
        <div style={{margin: "10px"}}>Icones "reliable", "fast", "package" et "green" réalisées par <a href="https://www.freepik.com" title="Freepik">Freepik</a> de <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        <div style={{margin: "10px"}}>Icone de formulaire réalisée par <a href="https://creativemarket.com/eucalyp" title="Eucalyp">Eucalyp</a> de <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        <Copyright />
      </Container>
    </footer>
  );
}