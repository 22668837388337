import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import { useHistory } from "react-router-dom";

import particularCustomer from "./../../assets/particular-customer.png";
import businessCustomer from "./../../assets/business-customer.png";

const ProductOffers = () => {

    // navigation history
    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        phoneIcon : {
            margin: "10px",
            border: "solid 4px #FE6565",
            borderRadius: "50%",
            padding: "5px",
            cursor: "pointer"
        },
        phoneNumber : {
          color: "#FF0000"
        },
        card: {
          maxWidth: 345,
          minHeight: 400
        },
        cardImg: {
          objectFit: "scale-down"
        }
      }));
    
      const classes = useStyles();

    const cards = [
        {
          cardId: 1,
          name: "Particuliers",
          media: particularCustomer,
          content: `Sprinterbox est le spécialiste de la livraison à vélo cargo de colis, de documents ou d’objets sur mesure à domicile, chez un voisin ou au bureau pour particuliers et professionnels dans un délai de 24h.
          `
        }, 
        {
          cardId: 2, 
          name: "Professionnels",
          media: businessCustomer,
          content: `Notre organisation logistique s’organise autour de deux zones d’attente situées en plein cœur de Paris nous permettant de vous offrir des solutions de livraison rapides et maitrisées.`
        },
    ];

    return (
        <Container 
            component="section" 
            sx={{ mt: 10, display: 'flex' }}
            style={{
                padding: '1rem 0',
            }}   
        >
            <Grid container spacing={3} justify="center">
                {cards.map((card) => (
                    <Grid item key={card.cardId} justify="space-around">
                        <Card className={classes.card}>
                            <CardActionArea style={{height:"350px"}}>
                                <CardMedia
                                    component="img"
                                    alt={card.name}
                                    height="140"
                                    image={card.media}
                                    className={classes.cardImg}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                    {card.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {card.content}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={() => {history.push("/quote")}}>
                                    Estimer une course
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Grid 
                container 
                justify="center" 
                alignItems="center"
                style={{
                    padding: '1rem 0',
                }}  
            >
                <IconButton href="tel:+33622879377">
                    <PhoneIcon 
                    fontSize="large" 
                    color="secondary" 
                    className={classes.heroPhoneIcon}
                    />
                </IconButton>
                <Link href="tel:+33622879377" color="secondary">
                    06.22.87.93.77
                </Link>
            </Grid>
        </Container>
    )
}

export default ProductOffers
