import * as React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import productCurvyLines from "./../../assets/productCurvyLines.png";
import reliable from "./../../assets/reliable.svg";
import fast from "./../../assets/fast.svg";
import green from "./../../assets/green.svg";

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.contrastText' }}
      style={{
        backgroundImage: `url(${productCurvyLines})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
      }}      
    >
      <Container 
        style={{
          paddingTop: '1rem',
          paddingBottom: '3rem'
        }}
      >
        <Grid 
          container 
          spacing={5}
        >
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={reliable}
                alt="fiable"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Fiable
              </Typography>
              <Typography variant="h5">
                {
                  'Pour vos besoins urgents de livraison sur de courtes et moyennes distances'
                }

                {
                  ', Sprinterbox dispose d\'une flotte cyclable efficace pour circuler aisément en milieu urbain sans subir les aléas du trafic.'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={fast}
                alt="fast"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Rapide
              </Typography>
              <Typography variant="h5">
                {
                  'En empruntant les pistes cyclables,'
                }

                {' le coursier à vélo est certain de livrer rapidement les colis en s’affranchissant des zones embouteillées.'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={green}
                alt="green"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Ecologique
              </Typography>
              <Typography variant="h5">
                {'Avec zéro émission de carbone et aucune nuisance sonore, '}

                {'Sprinterbox vous permet de participer à la Transition Écologique et la Croissance Verte afin d’augmenter votre Credit Carbone.'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
