import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player';
import { useHistory } from "react-router-dom";

import heroVideo from "./../../assets/hero_video.MP4";

const useStyles = makeStyles( theme => ({
	root: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(1, 0, 2),
		marginTop: theme.spacing(1),
		width: '100%',
		position: 'relative',
		'& video': {
			objectFit: 'fill',
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.up('sm')]: {
			height: '80vh',
			minHeight: 500,
			maxHeight: 1300,
		},
	},
	overlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0)',
	},
	heroContentDiv : {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexWrap: "wrap"
	},
	heroTitle: {
		paddingBottom: theme.spacing(2),
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: "2rem",
		},
	},
	heroSubtitle: {
		fontSize: "1rem",
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.contrastText,
		padding: theme.spacing(1.5),
		borderRadius: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			fontSize: "0.5rem",
		},
	}
}));

const Hero = () => {

	const classes = useStyles();

	const history = useHistory();

	return (
		<section className={classes.root}>
			<ReactPlayer
				url={heroVideo}
				playing
				loop
				muted
				width="75%"
				height="100%"
			/>
			<div className={classes.overlay}>
				<Box
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					color="#fff"
					className={classes.heroContentDiv}
				>
					<Typography variant="h2" component="h2" className={classes.heroTitle}>
						Sprinterbox.fr
					</Typography>
					<Typography 
						variant="h2" 
						component="h2"
						className={classes.heroSubtitle}
						align="center"
						style={{margin:"15px", fontWeight:"bold"}}
					>
						DITES ADIEU AUX FILES D’ATTENTE A LA POSTE ET AUX POINTS RELAIS
					</Typography>
					<Button 
						color="primary" 
						variant="contained" 
						size="large" 
						style={{fontSize:"1.2rem"}}
						onClick={() => history.push( "/quote" )}
					>
						Estimer une course
					</Button>
				</Box>
			</div>
		</section>
	);
};

export default Hero;
