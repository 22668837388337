import React, {Fragment} from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

import Markdown from '../modules/Markdown';
import NavBar from '../components/NavBar';

export const LegalNotice = () => {
  return (
    <Fragment>
      <NavBar />
      <Container style={{minHeight: "70vh"}}>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Mentions légales
          </Typography>
          <Markdown>{`
Dernière mise à jour: Le 29 août 2021.

Conformément aux dispositions de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est porté à la connaissance des utilisateurs du site www.sprinterbox.fr (ci-après le « Site ») les informations suivantes :

- Le Site est édité par la société Sprinterbox.fr
- Forme : Société par actions simplifiée
- Montant du capital social : 2.000 Euros
- Numéro d’immatriculation au RCS : 894 507 805 Paris
- Adresse du siège social : 8Bis, Rue Abel 75012 Paris
- Numéro de téléphone : 09.54.65.36.84
- Adresse électronique : contact@sprinterbox.fr
- Président : Cheikh Traoré
- Le directeur de publication du Site est Monsieur Cheikh Traoré
- Le Site est hébergé par la société OVH
- Forme : Société par actions simplifiée
- Montant du capital social : 10 174 560 Euros
- Numéro d’immatriculation au RCS : 424 761 419 au RCS de Lille
- Adresse du siège social : 2 Rue Kellermann, 59100 ROUBAIX
- Numéro de TVA intracommunautaire : FR 22424761419
          `}</Markdown>
        </Box>
      </Container>
    </Fragment>
  );
}

export default LegalNotice;
