import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: "#AF1578",
      light: "#bf4393",
      dark: "#8c1060",
      contrastText: "#fff"
    },
    secondary: {
      main: "#FE6565",
      light: "#fe8383",
      dark: "#cb5050",
      contrastText: "#fff"
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      light: green[50],
      main: green[500],
      dark: green[700],
    }
  },
  typography: {
    fontSize: 14,
    fontWeightLight: 300, 
    fontWeightRegular: 400, 
    fontWeightMedium: 700,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 568,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  }
});

export const MuiThemeProvider = ({ children }) => {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
