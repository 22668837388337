import * as React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import productCurvyLines from "./../../assets/productCurvyLines.png";
import form from "./../../assets/form.svg";
import box from "./../../assets/box.svg";
import logo from "./../../assets/logo.png";

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const useStyles = makeStyles((theme) => ({
  imgBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

function ProductHowItWorks() {

  const classes = useStyles();

  const history = useHistory();

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.contrastText' }}
      style={{
        backgroundImage: `url(${productCurvyLines})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
      }}      
    >
      <Container 
        style={{
          marginTop: '1rem',
          paddingTop: '1rem',
          paddingBottom: '3rem'
        }}
      >
        <Typography variant="h4" marked="center" align="center" component="h2" style={{margin:"1.5rem"}}>
          Comment ça marche.
          <span style={{
            width: "55px",
            height: "4px",
            margin: "2px auto 0",
            display: "block",
            backgroundColor: "#FE6565"
          }}></span>
        </Typography>
        <Grid 
          container 
          spacing={5}
        >
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={form}
                className={classes.imgBox}
                alt="form"
                sx={{ height: 55 }}
              />
              <Typography variant="h5" style={{textAlign:"center"}}>
                {
                  'Besoin d\'un service de livraison ?'
                }

                {
                  ' Rendez-vous sur le formulaire pour'
                }
                <Button 
                  color="primary" 
                  variant="contained" 
                  size="large" 
                  style={{
                    fontSize:"1rem", 
                    margin: "1rem"
                  }}
                  onClick={() => history.push( "/quote" )}
                >Estimer une course</Button>                       
              </Typography>       
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={box}
                className={classes.imgBox}
                alt="box"
                sx={{ height: 55 }}
              />
              <Typography variant="h5">
                {
                  'Indiquez les dimensions de votre colis,'
                }

                {' ainsi que les adresses d\'enlèvement et de livraison.'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src={logo}
                className={classes.imgBox}
                alt="logo"
                sx={{ height: 55 }}
              />
              <Typography variant="h5">
                {'Un membre de l\'équipe de SprinterBox vous rappelle pour convenir de la suite de votre livraison, '}

                {'Vous êtes entre de bonnes mains !'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;