
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Switch, BrowserRouter as Router, Route} from "react-router-dom";

import { MuiThemeProvider as ThemeProvider } from './theme';

// import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Quote from './pages/Quote';
import { loadScriptByURL } from './helpers/apis-helpers';
// TODO find alternative to recaptcha
// import { gRecaptchaSiteKey } from "./conf";
import PrivacyNotice from './pages/PrivacyNotice';
import LegalNotice from './pages/LegalNotice';

function App() {

  // useEffect( () => {
  //   loadScriptByURL( "recaptcha-key" , `https://www.google.com/recaptcha/api.js?render=${gRecaptchaSiteKey}&hl=fr`, () => {
  //     console.info( "page is protected with Google Recaptcha" );
  //   });
  // }, []);

  return (
    <CssBaseline>
      <ThemeProvider>
        <Router>
          {/* <NavBar/> */}
          <Switch>
            <Route exact path="/legal-notice" component={LegalNotice} /> 
            <Route exact path="/privacy-notice" component={PrivacyNotice} /> 
            <Route exact path="/quote" component={Quote} /> 
            {/* home is the catch-all for now */}
            <Route component={Home} /> 
          </Switch>
          <Footer/>
        </Router>
      </ThemeProvider>
    </CssBaseline>
  );
}

export default App;
