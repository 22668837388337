import React, { Fragment } from 'react';
import Hero from '../components/Home/Hero';
import ProductCTA from '../components/Home/ProductCTA';
import ProductGallery from '../components/Home/ProductGallery';
import ProductHowItWorks from '../components/Home/ProductHowItWorks';
import ProductOffers from '../components/Home/ProductOffers';
import ProductValues from '../components/Home/ProductValues';

const Home = () => {
    return <Fragment>
        <Hero />
        <ProductValues/>
        <ProductOffers/>
        <ProductGallery/>
        <ProductHowItWorks/>
        <ProductCTA/>
    </Fragment> 
}

export default Home
